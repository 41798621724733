<template>
	<div class="settings-content">
		<template v-if="soft_procedures.is_active">
			<template v-if="loaded">
				<div class="opacity-page">
					<div class="list-crud row-space-tbf">
						<div class="space-left"></div>
						<div class="content">
							<div class="list">
								<div class="item" v-for="category in categories" @click="viewItem(category)" v-bind:class="{active: categoySelected.id == category.id}">
									<div class="name">{{ category.name }}</div>
									<div class="actions">
										<button @click.stop="editCategory(category)"><icon-edit /></button>
										<button @click.stop="showModal('delete', {type: 'category', from: 'index_categories', model: category})"><icon-trash /></button>
									</div>
								</div>
								<div class="item-empty" v-if="!categories.length">
									{{ $t('roles.any_result') }}
								</div>
							</div>
							<div class="crud" id="list-items">
								<div class="box" v-if="viewCrud == 'create'">
									<div class="header">
										<div class="icon"><icon-category /></div>
										<div class="name">{{ $t('categories.add_new_category') }}</div>
									</div>
									<div class="form">
										<div class="input-group">
											<div class="label-header">
												<label class="label">{{ $t('categories.name') }}*</label>
												<div v-if="$v.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
												<div v-if="errorsBe.name" class="error-msg">{{ errorsBe.name.join(" | ") }}</div>
											</div>
											<div class="input-box bg-white" v-bind:class="{has_error: $v.name.$error}">
												<div class="icon-right" v-if="name != ''" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
												<input type="text" :placeholder="$t('categories.name_placeholder')" class="input-text no-icon" v-model="name">
											</div>
										</div>

										<div class="input-group">
											<div class="label-header">
												<label class="label">{{$t('categories.parent_category')}}</label>
											</div>
											<div class="input-box bg-white">
												<div class="icon-left"><icon-category /></div>
												<div class="icon-right" v-if="parent != ''" @click.stop="parent = ''"><icon-close class="icon-clear" /></div>
												<multiselect 
												v-model="parent"
												v-bind:class="{populate: parent != ''}"
												:options="filterCategories"
												:allow-empty="true"
												:show-labels="false"
												track-by="id" 
												label="name"
												>
													<template slot="placeholder" slot-scope="props">
														<span class="text">
															{{ $t('categories.choose_category') }}
														</span>
													</template>
													<template slot="option" slot-scope="props">
														<div :class="'option-mg level-' + props.option.level_depth" :style="'padding-left: calc(10px * ' + props.option.level_depth + ');'">
															<div class="icon-level" v-if="props.option.level_depth != 0"><icon-level-up /></div>
															<div class="'option_ellipsis">{{ props.option.name }}</div>
														</div>
													</template>
													<template slot="noResult">{{$t('categories.no_results')}}</template>
													<template slot="noOptions">{{$t('categories.empty_list')}}</template>>
												</multiselect>
											</div>
										</div>

										<div class="form-submit">
											<div class="error-msg-settings" v-show="error_message!=''">{{error_message}}</div>
											<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')">
												<div class="loader"></div>
												<span class="text">{{$t('general.add')}}</span>
											</button>
										</div>
									</div>
								</div>
								<div class="box bg-white" v-else-if="viewCrud == 'edit'">
									<div class="header">
										<div class="icon"><icon-category /></div>
										<div class="name">{{ categoySelected.name }}</div>
										<div class="action-close">
											<button class="btn-tbf white only-icon" @click="resetToList('item')"><icon-close /></button>
										</div>
									</div>
									<div class="form">
										<div class="input-group">
											<div class="label-header">
												<label class="label">{{$t('categories.edit_category')}}</label>
											</div>
											<div class="input-box" v-bind:class="{has_error: $v.name.$error}">
												<div class="icon-right" v-if="name != ''" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
												<input type="text" :placeholder="$t('categories.name_placeholder')" class="input-text no-icon" v-model="name">
											</div>
										</div>

										<div class="input-group">
											<div class="label-header">
												<label class="label">{{$t('categories.parent_category')}}</label>
											</div>
											<div class="input-box">
												<div class="icon-left"><icon-category /></div>
												<div class="icon-right" v-if="parent != ''" @click.stop="parent = ''"><icon-close class="icon-clear" /></div>
												<multiselect 
												v-model="parent"
												v-bind:class="{populate: parent != ''}"
												:options="filterCategories"
												:allow-empty="true"
												:show-labels="false"
												track-by="id" 
												label="name"
												>
													<template slot="placeholder" slot-scope="props">
														<span class="text">
															{{ $t('categories.choose_category') }}
														</span>
													</template>
													<template slot="option" slot-scope="props">
														<div :class="'option-mg level-' + props.option.level_depth" :style="'padding-left: calc(10px * ' + props.option.level_depth + ');'">
															<div class="icon-level" v-if="props.option.level_depth != 0"><icon-level-up /></div>
															<div class="'option_ellipsis">{{ props.option.name }}</div>
														</div>
													</template>
													<template slot="noResult">{{$t('categories.no_results')}}</template>
													<template slot="noOptions">{{$t('categories.empty_list')}}</template>>
												</multiselect>
											</div>
										</div>

										<div class="form-submit">
											<div class="error-msg-settings" v-show="error_message!=''">{{error_message}}</div>
											<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')">
												<div class="loader"></div>
												<span class="text">{{$t('general.update')}}</span>
											</button>
										</div>
									</div>
								</div>
								<div class="box bg-white" v-else-if="viewCrud == 'view'">
									<div class="header">
										<div class="icon"><icon-category /></div>
										<div class="name">{{ categoySelected.name }}</div>
										<div class="action-close">
											<button class="btn-tbf blue only-icon" @click="editCategory(categoySelected)">
												<div class="icon"><icon-edit /></div>
											</button>
											<button class="btn-tbf white only-icon" @click="resetToList('item')"><icon-close /></button>
										</div>
									</div>
									<div class="data-item">
										<div class="label">{{$t('categories.name')}}</div>
										<div class="text" >{{ categoySelected.name }}</div>
										<template v-if="categoySelected.parent_id">
											<div class="label">{{$t('categories.parent_category')}}</div>
											<div class="text" >{{ this.categories.find(el => el.id == categoySelected.parent_id).name }}</div>
										</template>
									</div>
								</div>
							</div>
						</div>
						<div class="space-right"></div>
					</div>
				</div>
			</template>
			<loader-settings-page v-else/>
		</template>
		<template v-else>
			<div class="row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<not-module class="with-border-top" />
				</div>
				<div class="space-right"></div>
			</div>
		</template>
	</div>
</template>

<script>
	import NotModule from '@/components/General/NotModule'
	import IconClose from '../../Icons/Close'
	import IconEdit from '../../Icons/Edit'
	import IconCategory from '../../Icons/Category'
	import IconTrash from '../../Icons/Trash'
	import IconLevelUp from '../../Icons/LevelUp'
	import LoaderSettingsPage from '../../PagesLoaders/SettingsPage'

	import { required } from 'vuelidate/lib/validators'

	export default {
		components: {
			NotModule,
			IconClose,
			IconEdit,
			IconTrash,
			IconLevelUp,
			IconCategory,
			LoaderSettingsPage
		},
		computed: {
			soft_procedures() {
				return this.$store.getters['applications/getApplication']('Proceduri');
			}
		},
		data() {
			return {
				loaded: false,
				viewCrud: 'create',
				categories: [],
				categoySelected: '',
				name: '',
				parent: '',
				error_message: '',
				errorsBe: {},
				parent_category_edit: false,
				filterCategories: []
			}
		},
		async mounted(){
			if( this.soft_procedures.is_active ) {
				await this.getFilters()
				await this.getCategories()
			}

			setTimeout(() => {
				var title = this.$t('settings-navbar.categories');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshCategoriesIndex', () => {
				this.getCategories()
				this.getFilters()
			})
		},
		validations: {
			name: {required}
		},
		methods: {
			async getFilters(){
				await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: {categories: true, parent_id: this.parent_category_edit }})
				.then(({data}) => {
					this.filterCategories = data.data.categories
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => { this.loaded = true })
			},
			async getCategories(){
				await axios.get( this.$auth.user().instance.id + '/categories')
				.then(({data}) => {
					this.categories = data.data
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					this.loaded = true
					setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
				})
			},
			saveAction(type){
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true

				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = ''

				this.$v.$touch()
				if(!this.$v.$invalid){
					var objData = {
						name: this.name.charAt(0).toUpperCase() + this.name.slice(1),
						parent_id: this.parent ? this.parent.id : '',
					}
					if(type == 'create'){
						this.createCategory(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateCategory(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createCategory(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/categories/store', objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.$v.$reset()
						this.getCategories()
						this.getFilters();
						setTimeout(()=>{
							this.resetToList()
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.add')
							this.categoySelected = data.category;
							this.viewCrud = 'view'
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			updateCategory(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.patch(`/categories/${this.categoySelected.id}`, objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.getCategories()
						this.getFilters();
						this.$v.$reset()
						setTimeout(()=>{
							this.resetToList()
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = 'Create'
							this.categoySelected = this.categories.find(el => el.id == this.categoySelected.id)
							this.viewCrud = 'view'
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			editCategory(category){
				if(this.categories.find(el => el.$isDisabled)){
					this.categories.find(el => el.$isDisabled).$isDisabled = false
				}
				this.scrollTop()
				this.viewCrud = 'edit'
				
				this.parent_category_edit = category.id;
				this.categoySelected = category
				this.name = category.name
				this.parent = category.parent_id ? this.categories.find(el => el.id == category.parent_id) : '';

				this.getFilters().then(() => {
					this.filterCategories.find(el => el.id == category.id).$isDisabled = true;
				})
			},
			resetToList(from){
				this.viewCrud = 'create'
				if(from == 'item') {
					this.categoySelected = ''
				}
				this.name = '';
				this.parent = '';

				if(this.categories.find(el => el.$isDisabled)){
					this.categories.find(el => el.$isDisabled).$isDisabled = false
				}
			},
			viewItem(item){
				this.scrollTop()
				this.viewCrud = 'view'
				this.categoySelected = item
				this.parent = ''
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			scrollTop() {
				var top_value = {scrollTop:$('#list-items').position().top}
				if(top_value.scrollTop !== 135){
					$('div').animate({scrollTop:0}, 'slow');
				}
			}
		}
	};
</script>